$(document).on("turbolinks:load", function() {
  $.scrollDepth({
    elements: [
      "#header",
      "#about",
      "#mission",
      "#projects",
      "#team",
      "#testimonials",
      "#contact",
      "#map-section",
      "#footer"
    ]
  });
});
